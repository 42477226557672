import React from "react";
import { Link } from "gatsby";
import logo from "../media/logo.svg";
import ianz from "../media/ianz.jpg";
import { FaHome } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";

const navHandler = (toggleNav) => () =>
  document.querySelector(".navbar-menu").classList.toggle("is-active");

const Navbar = ({ toggleNav }) => (
  <nav className="navbar is-transparent is-fixed-top">
    <div className="container">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <figure className="image">
            <img
              src={logo}
              alt="Pathlab logo"
              style={{ width: "200px", maxHeight: "4em" }}
            />
          </figure>
        </Link>
        <div
          className="navbar-burger"
          role="button"
          aria-label="menu"
          aria-expanded="false"
          data-target="pathlabNavbar"
          onClick={navHandler(toggleNav)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>
      <div id="pathlabNavbar" className="navbar-menu">
        <div className="navbar-start">
          <Link className="navbar-item" to="/" activeClassName="is-active">
            <FaHome />
          </Link>
          <Link
            className="navbar-item"
            to="/search"
            activeClassName="is-active"
          >
            <FaSearch />
          </Link>
          <div className="navbar-item has-dropdown is-hoverable">
            <Link
              className="navbar-item"
              to="/about"
              activeClassName="is-active"
            >
              About
            </Link>
            <div className="navbar-dropdown">
              <Link
                className="navbar-item"
                to="/services"
                activeClassName="is-active"
              >
                Our Services
              </Link>
              <Link
                className="navbar-item"
                to="/people"
                activeClassName="is-active"
              >
                Our People
              </Link>
              <Link
                className="navbar-item"
                to="/about#values"
                activeClassName="is-active"
              >
                Our Values
              </Link>
              <Link
                className="navbar-item"
                to="/accreditation"
                activeClassName="is-active"
              >
                <figure className="image">
                  <img
                    src={ianz}
                    alt="IANZ logo"
                    style={{ width: "100px", maxHeight: "2em" }}
                  />
                </figure>
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <Link
              className="navbar-item"
              to="/news"
              activeClassName="is-active"
            >
              News
            </Link>
            <div className="navbar-dropdown">
              <Link
                className="navbar-item"
                to="/archive"
                activeClassName="is-active"
              >
                Archive
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <Link
              className="navbar-item"
              to="/patients"
              activeClassName="is-active"
            >
              Patients
            </Link>
            <div className="navbar-dropdown">
              <Link
                className="navbar-item"
                to="/locations"
                activeClassName="is-active"
              >
                Our Locations & Opening Hours
              </Link>
              <Link
                className="navbar-item"
                to="/prepare"
                activeClassName="is-active"
              >
                Preparing for your Tests
              </Link>
              <Link
                className="navbar-item"
                to="/appointments"
                activeClassName="is-active"
              >
                Appointments for Special Testing
              </Link>
              <Link
                className="navbar-item"
                to="/payment"
                activeClassName="is-active"
              >
                Test Costs
              </Link>
              <Link
                className="navbar-item"
                to="/patient-requested"
                activeClassName="is-active"
              >
                Patient Requested Testing
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <Link
              className="navbar-item"
              to="/providers"
              activeClassName="is-active"
            >
              Providers
            </Link>
            <div className="navbar-dropdown">
              <Link
                className="navbar-item"
                to="/providers#forms"
                activeClassName="is-active"
              >
                Forms
              </Link>
              <Link
                className="navbar-item"
                to="/providers#testing-guides"
                activeClassName="is-active"
              >
                Testing Guides
              </Link>
              <Link
                className="navbar-item"
                to="/test-add"
                activeClassName="is-active"
              >
                Test Add / Clinical Details
              </Link>
              <Link
                className="navbar-item"
                to="/providers#home-visits"
                activeClassName="is-active"
              >
                Home Visits
              </Link>
              <Link
                className="navbar-item"
                to="/molecular"
                activeClassName="is-active"
              >
                Molecular Testing
              </Link>
              <Link
                className="navbar-item"
                to="/hpv"
                activeClassName="is-active"
              >
                HPV Testing
              </Link>
              <Link
                className="navbar-item"
                to="/eorders"
                activeClassName="is-active"
              >
                Electronic Ordering
              </Link>
              <Link
                className="navbar-item"
                to="/supplies"
                activeClassName="is-active"
              >
                Order Supplies
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-item" href="https://tests.pathlab.co.nz">
              Test Index
            </a>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <Link
              className="navbar-item"
              to="/contact"
              activeClassName="is-active"
            >
              Contact Us
            </Link>
            <div className="navbar-dropdown">
              <Link
                className="navbar-item"
                to="/research"
                activeClassName="is-active"
              >
                Trials and Research
              </Link>
              <Link
                className="navbar-item"
                to="/commercial-testing"
                activeClassName="is-active"
              >
                Commercial Testing
              </Link>
              <Link
                className="navbar-item navbar-last"
                to="/careers"
                activeClassName="is-active"
              >
                Careers
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
);

export default Navbar;
